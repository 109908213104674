import { apiConnector } from "../ApiConnector"
import { setLoading, setUser } from "../../SLICES/AuthSlice"
import { logout } from "./AuthOperations"
import { userEndpoints } from "../apis"
import { enqueueSnackbar, closeSnackbar } from "notistack"
import { setData } from "../../SLICES/certificate"
import axios from "axios"

const {
    GET_USER_DETAILS_API,
    GET_USER_ENROLLED_COURSES_API,
    GENERATE_CERTIFICATE,
    GET_CERTIFICATE_DETAILS,
    BUY_COURSE,
    BUY_COURSE_RESPONSE_API,
    UPLOAD_PAYMENT_SCREENSHOT,
    GET_PAYMENT_SLIPS
} = userEndpoints

export function getUserDetails(token, navigate) {
    return async (dispatch) => {
      const snackId = enqueueSnackbar("Loading...", {vairent: 'info'})
      dispatch(setLoading(true))
      try {
        const response = await apiConnector("GET", GET_USER_DETAILS_API , null, {
          Authorization: `Bearer ${token}`,
        })
        // console.log("GET_USER_DETAILS API RESPONSE............", response)
  
        if (!response.data.success) {
          throw new Error(response.data.message)
        }
        const userImage = response.data.data.image
          ? response.data.data.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.data.firstName} ${response.data.data.lastName}`
        dispatch(setUser({ ...response.data.data, image: userImage }))
        dispatch(setLoading(false))
        closeSnackbar(snackId)
        // navigate('/dashboard/my-profile')
      } catch (error) {
        dispatch(logout(navigate))
        // console.log("GET_USER_DETAILS API ERROR............", error)
        enqueueSnackbar("Could Not Get User Details", {vairent:'error'})
      }
      finally{
        dispatch(setLoading(false))
        closeSnackbar(snackId)
      }

    }
  }

  export async function getUserEnrolledCourses(token) {
    let snackId = enqueueSnackbar("Loading...",{vairent:'info'})
    let result = []
    try {
      const response = await apiConnector(
        "GET",
        GET_USER_ENROLLED_COURSES_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      )
      // // console.log(
      //   "GET_USER_ENROLLED_COURSES_API API RESPONSE............",
      //   response
      // )
  
      if (!response.data.success) {
        throw new Error(response.data.message)
      }
      result = response.data.data
    } catch (error) {
      // console.log("GET_USER_ENROLLED_COURSES_API API ERROR............", error)
      enqueueSnackbar("Could Not Get Enrolled Courses",{variant:'error'})
    }
    closeSnackbar(snackId)
    return result
  }
  
  export function generateCertificateData({name, courseName, attendeeName, attendeeEmail, seminarName, dateOfSeminar, seminarCertificate, token}) {
    
    return async (dispatch)=>{
      let snackId = enqueueSnackbar("Processing your request....", {variant:'info'})
      try {
        
        const response = await apiConnector('POST',GENERATE_CERTIFICATE,{
          name,
          courseName,
          attendeeEmail,
          attendeeName,
          seminarName,
          dateOfSeminar,
          seminarCertificate
        },{
          Authorization: `Bearer ${token}`
        })
        console.log(response)
        if(!response.data.success){
          throw new Error(response.data.message)
        }
        if(!response.data.seminarCertificate){
          dispatch(setData(response?.data?.certificateData))
        }
        else{
          enqueueSnackbar('Qr Data saved successfuly!!',{variant:'success'})
          console.log(response)
          return response;
        }
        // result = response.data.certificateData
      } catch (error) {
        console.log("GENERATE_CERTIFICATE_DATA API ERROR............", error)
        enqueueSnackbar("Could Not Generate Data lulu",{variant:'error'})
      }
      closeSnackbar(snackId)
    
    }
    
  }

  export async function getCertificateDetails(id){
    let result = null
    const snackId = enqueueSnackbar("Fetching Details",{variant:'info',persist:true})
    try {
      const response = await apiConnector('GET',`${GET_CERTIFICATE_DETAILS}/${id}`)

      if(!response.data.success){
        throw new Error(response.data.error)
      }
      // console.log('GET CERTIFICATE DATA DETAILS.....', response)
      result = response?.data?.certificateData
    } catch (error) {
      // console.log("GET_CERTIFICATE_DETAILS API ERROR............", error)
      enqueueSnackbar("Could Not Get Certificate Data",{variant:'error'}) 
    }
    closeSnackbar(snackId)
    return result
  }

  export async function BuyCourse({formdata, token}){
    let result = null;
    console.log("formdata is: ", formdata)
    try {
      var requiredStr = `merchant_id=3407279&order_id=1234&amount=4000&currency=INR&redirect_url=${BUY_COURSE_RESPONSE_API}&cancel_url=${BUY_COURSE_RESPONSE_API}&language=EN`
      result = await axios.post(`${BUY_COURSE}`,requiredStr ,{
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/plain'
    })
      if(!result?.data.success){
        throw new Error('Unable to serve your request at the moment...')
      }

      result = "Payment Successful"
      console.log("Payment Successful")
    } catch (error) {
      enqueueSnackbar(`${error}`,{variant:'error'}) 
    }

    
    console.log(requiredStr)

    return result
  }

  export async function UploadScreenShot({token, screenshot}){
    let res = null;
    try {
      res = await apiConnector('POST',`${UPLOAD_PAYMENT_SCREENSHOT}`,{screenshot},{
        Authorization: `Bearer ${token}`,
      })


      if(!res.data.success){
        throw new Error(res.data.message)
      }
      // console.log(res.data)
      enqueueSnackbar(res.data.message,{variant:'success'});
    } catch (error) {
      enqueueSnackbar(error,{variant:'error'})
    }
    return res
  }

  export async function getPaymentSlips({token}){
    var res = null;
    try {
      res = await apiConnector('GET',`${GET_PAYMENT_SLIPS}`,{
        Authorization: `Bearer ${token}`,
      })
      // console.log("Response of payment slips: ", res)

      if(!res.data.success){
        throw new Error(res.data.message)
      }

    } catch (error) {
      enqueueSnackbar(error,{variant:'error'})
    }
    return res
  }