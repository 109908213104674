
export default function Tab({ tabData, field, setField }) {

    return (
      <div
        style={{
          boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
        }}
        className="flex bg-btn-red p-1 gap-x-1 rounded-full max-w-max"
      >
        {tabData.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setField(tab.type)}
            className={`${
              field === tab.type
                ? "bg-black bg-opacity-35 text-white font-bold"
                : "bg-transparent text-richblack-200"
            } py-2 px-5 rounded-full transition-all duration-200 active:bg-slate-600 active:text-black`}
          >
            {tab?.tabName}
          </button>
        ))}
      </div>
    );
  }
  