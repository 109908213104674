import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RxCross2 } from "react-icons/rx";
import { AiOutlineDown } from 'react-icons/ai';
import { IoIosArrowBack } from "react-icons/io";
import ConfirmationModal from '../../COMMON/ConfirmationModal';
// import SidebarAccordion from './SidebarAccordion';

const Sidebar = ({ courses, setSelectedCourse, selectedCourse, viewSideBar, setViewSideBar, viewHamBurgerMenuButton, setViewHamBurgerMenuButton, paymentModal, setPaymentModal }) => {

  // // console.log(publishedCourses)
  // // console.log(liveCourses)
  // // console.log(upcomingCourses)
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.auth)
  const [hoveredLink, setHoverdLink] = useState()
  // const [publishedCourses, setPublishedCourses] = useState(courses.filter(course => course.status === 'Published'))
  const [sectionHeight, setSectionHeight] = useState(0)
  // const publishedCourses = courses.filter(course => course.status === 'Published')
  // // console.log(publishedCourses)
  const [liveHeight, setLiveSectionHeight] = useState(0)
  const [upcomingHeight, setUpcomingSectionHeight] = useState(0)
  // // console.log("All courses are: ",courses)
  const COURSE_STATUS = ['Upcoming', 'Live', 'Published']
  const [active, setActive] = useState(COURSE_STATUS[2])
  // console.log(active)
  const [viewLiveCourses, setViewLiveCourses] = useState(false)
  const [viewUpcomingCourses, setViewUpcomingCourses] = useState(false)
  const [viewPublishedCourses, setViewPublishedCourses] = useState(true)


  const publishRef = useRef(null)
  const liveRef = useRef(null)
  const upcomingRef = useRef(null)

  function setUpcomingHeight() {
    setViewPublishedCourses(false);
    setViewLiveCourses(false);
    setUpcomingSectionHeight(viewUpcomingCourses ? upcomingRef.current.scrollHeight + 10 : 0)
    // console.log(upcomingHeight)
    // console.log(sectionHeight)
    // // console.log(upcomingRef.current.scrollHeight)
    // setHeights()
  }

  function setPublishedHeight() {
    setViewUpcomingCourses(false);
    setViewLiveCourses(false);
    setSectionHeight(viewPublishedCourses ? publishRef.current.scrollHeight + 20 : 0)

    // console.log(sectionHeight)
    // // console.log(publishRef.current.scrollHeight)
    // setHeights()
  }

  function setLiveHeight() {
    setViewPublishedCourses(false);
    setViewUpcomingCourses(false);
    setLiveSectionHeight(viewLiveCourses ? liveRef.current.scrollHeight + 10: 0)
    // console.log(liveHeight)
    // // console.log(liveRef.current.scrollHeight)
    // setHeights()
  }

  useEffect(() => {
    // categorizeCourses()

    setSectionHeight(viewPublishedCourses ? publishRef.current.scrollHeight : 0)
    setLiveSectionHeight(viewLiveCourses ? liveRef.current.scrollHeight : 0)
    setUpcomingSectionHeight(viewUpcomingCourses ? upcomingRef.current.scrollHeight  : 0)
    // setHeights()
  })

  const filterCourse = (id) => {
    const filteredCourse = courses.filter((course) => course._id === id)
    setSelectedCourse(filteredCourse[0])
    // // console.log(filteredCourse)
  }

  // // console.log(publishedCourses)

  return (
    <>
      <div className={`w-[25%] md:w-[20%] box-border h-full sm:border-r-2 ${viewSideBar ? "block absolute w-[98%] bg-white z-20 " : "w-0 relative "} ${viewSideBar && "ease-out w-full origin-right transition-[width] duration-[.5s] z-10 "}`}>
        <div className={`h-full flex-col box-border justify-center sm:block items-start gap-16 ${!viewSideBar && "hidden"}`}>
          <RxCross2 className={`sm:hidden mt-4 ml-2 p-2 text-4xl hover:bg-[#e5e2e2] hover:cursor-pointer ${viewSideBar && !viewHamBurgerMenuButton && "block"}`} onClick={() => { setViewSideBar((prev) => !prev); setViewHamBurgerMenuButton((prev) => !prev) }} />
          <h1 className='text-lg sm:text-2xl font-bold flex items-center gap-2 text-black p-2'><span className='hover:cursor-pointer '><a href='https://zieglarbyte.com/'><IoIosArrowBack /></a></span>Zieglar Byte</h1>
          <div className='w-full sm:h-[73%] h-[63%] overflow-y-scroll gap-6 text-lg flex flex-col mt-4 overflow-auto overflow-x-hidden p-1'>

            <>
              <div
                className='w-full sm:text-base md:text-xl font-bold flex justify-between items-center my-2'
                onClick={() => { setViewPublishedCourses((prev) => !prev); setActive(COURSE_STATUS[2]); setPublishedHeight() }}
              >
                Published Courses <span className={`${viewPublishedCourses ? "rotate-0" : "rotate-180"}`}><AiOutlineDown /></span>
              </div>
              {<div ref={publishRef} className='relative h-0 box-border overflow-hidden bg-richblack-900 transition-[height] duration-[0.35s] ease-[ease]'
                style={{
                  height: sectionHeight 
                }}>
                {
                  viewPublishedCourses && active === 'Published' && courses.filter(course => course.status === 'Published').map((course) => {
                    return (
                      <div
                        key={course._id}
                        className='w-[99%] ml-0.5'
                        onMouseEnter={() => {
                          if (selectedCourse._id !== course._id) {
                            setHoverdLink(() => course._id)
                          }
                        }}
                        onMouseLeave={() => { setHoverdLink(null) }}
                      >
                        <p
                          className={`w-full max-md:font-semibold text-base sm:text-[12px] md:text-base box-border cursor-pointer flex items-center gap-2 ${hoveredLink === course._id && " bg-yellow-600 text-white font-medium p-2 h-10  duration-75 ease-linear z-10 text-[8px]"}`}
                          onClick={() => { if (viewSideBar) { setViewSideBar((prev) => !prev); setViewHamBurgerMenuButton((prev) => !prev) }; filterCourse(course._id) }}
                        >
                          <span className={` ${selectedCourse._id === course._id && " w-full bg-yellow-600 text-white font-bold py-2 px-4 duration-75 ease-linear"}`}>{course.courseName}</span>
                        </p>
                      </div>
                    )
                  })
                }
              </div>}
            </>

            <>
              <div
                className='w-full sm:text-base md:text-xl font-bold flex box-border justify-between items-center my-2'
                onClick={() => { setViewLiveCourses((prev) => !prev); setActive(COURSE_STATUS[1]); setLiveHeight() }}
              >
                Live Courses <span className={`${viewLiveCourses ? "rotate-0" : "rotate-180"}`}><AiOutlineDown /></span>
              </div>
              {<div ref={liveRef} className='relative h-0 overflow-hidden bg-richblack-900 transition-[height] duration-[0.35s] ease-[ease]'
                style={{
                  height: liveHeight 
                }}>
                {
                  active === 'Live' && courses.filter(course => course.status === 'Live').map((course) => {
                    return (
                      <div
                        key={course._id}
                        className='w-[99%] ml-0.5'
                        onMouseEnter={() => {
                          if (selectedCourse._id !== course._id) {
                            setHoverdLink(() => course._id)
                          }
                        }}
                        onMouseLeave={() => { setHoverdLink(null) }}
                      >
                        <p
                          className={`w-full max-md:font-semibold text-base sm:text-[12px] md:text-base box-border cursor-pointer flex items-center gap-2 ${hoveredLink === course._id && " bg-yellow-600 text-white font-medium p-2 h-10  duration-75 ease-linear z-10 text-[8px]"}`}
                          onClick={() => { if (viewSideBar) { setViewSideBar((prev) => !prev); setViewHamBurgerMenuButton((prev) => !prev) }; filterCourse(course._id) }}
                        >
                          <span className={`${selectedCourse._id === course._id && " w-full bg-yellow-600 text-white font-bold py-2 px-4 duration-75 ease-linear"}`}>{course.courseName}</span>
                        </p>
                      </div>
                    )
                  })
                }
              </div>}
            </>
            <>
              <div
                className='w-full sm:text-base md:text-xl font-bold flex box-border justify-between items-center my-2'
                onClick={() => { setViewUpcomingCourses((prev) => !prev); setActive(COURSE_STATUS[0]); setUpcomingHeight() }}
              >
                Upcoming Courses <span className={`${viewUpcomingCourses ? "rotate-0" : "rotate-180"}`}><AiOutlineDown /></span>
              </div>
              {<div ref={upcomingRef} className='relative h-0 overflow-hidden bg-richblack-900 transition-[height] duration-[0.35s] ease-[ease]'
                style={{
                  height: upcomingHeight 
                }}>
                {/* {// console.log(courses.filter(course => course.status === 'Upcoming'))} */}
                {
                  active === 'Upcoming' && courses.filter(course => course.status === 'Upcoming').map((course) => {
                    return (
                      <div
                        key={course._id}
                        className='w-[99%] ml-0.5'
                        onMouseEnter={() => {
                          if (selectedCourse._id !== course._id) {
                            setHoverdLink(() => course._id)
                          }
                        }}
                        onMouseLeave={() => { setHoverdLink(null) }}
                      >
                        <p
                          className={`w-full max-md:font-semibold text-base sm:text-[12px] md:text-base box-border cursor-pointer flex items-center gap-2 ${hoveredLink === course._id && " bg-yellow-600 text-white font-medium p-2 h-10 border-box duration-75 ease-linear z-10 text-[8px]"}`}
                          onClick={() => { if (viewSideBar) { setViewSideBar((prev) => !prev); setViewHamBurgerMenuButton((prev) => !prev) }; filterCourse(course._id) }}
                        >
                          <span className={`box-border ${selectedCourse._id === course._id && " w-full bg-yellow-600 text-white font-bold py-2 px-4 duration-75 ease-linear"}`}>{course.courseName}</span>
                        </p>
                      </div>
                    )
                  })
                }
              </div>}
            </>

            {/* {
              <SidebarAccordion course={publishedCourses} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} hoveredLink={hoveredLink} setHoverdLink={setHoverdLink} viewSideBar={viewSideBar} setViewSideBar={setViewSideBar} viewHamBurgerMenuButton={viewHamBurgerMenuButton} setViewHamBurgerMenuButton={setViewHamBurgerMenuButton} filterCourse={filterCourse} />
            } */}
          </div>
          <div className={` text-base sm:text-[12px] md:text-base flex flex-col gap-6 ${viewSideBar && 'mt-12'}`}>
            <div className='p-2 flex justify-center '>
              {token && <div className={`w-full md:w-10/12 lg:w-full bg-btn-red text-white text-base sm:text-[12px] md:text-lg p-1 lg:p-3 rounded-md m font-semibold text-center hover:cursor-pointer ${viewSideBar && "-mt-12 py-2 text-xl"}`}
                onClick={() => setPaymentModal((prev) => !prev)}
              >
                Make Payment
              </div>}
            </div>
            <div className='p-2 flex justify-center '>
              <div className={`w-full md:w-10/12 lg:w-full -mt-8 bg-btn-red text-white text-base sm:text-[12px] md:text-lg p-1 lg:p-3 rounded-md m font-semibold text-center hover:cursor-pointer ${viewSideBar && "-mt-12 py-2 text-xl"}`}
                onClick={() => {
                  if (!token) {
                    navigate('/login')
                  } else {
                    navigate('/dashboard/my-profile')
                  }
                }}
              >
                {
                  !token ? "Login" : "Dashboard"
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Sidebar