import React from 'react'
import { RxCross2 } from "react-icons/rx";

const ViewPaymentSS = ({setViewImage, srcImage}) => {
    return (
        <div className="fixed inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-white bg-opacity-10 backdrop-blur-sm">
          <div className="w-fit max-w-[45vw] rounded-lg border border-[#0000008a] bg-[#fff] bg-opacity-30 p-6">
            Payment Screenshot by {srcImage.user.firstName}
            <div className="w-fit text-end items-end justify-end float-right mr-0">
                <button className="w-fit text-xl text-white rounded-md p-2 bg-btn-red hover:cursor-pointer" onClick={()=>setViewImage((prev)=>!prev)}>
                    <RxCross2 />
                </button>
            </div>   
            { srcImage && <div className="w-full ">
                <img 
                    src={srcImage.file}
                    alt="Preview"
                    className="h-full w-full rounded-md object-cover "
                />  
                </div>
            }   
          </div>
        </div>
      )
}

export default ViewPaymentSS
