export default function IconBtn({
    text,
    onclick,
    children,
    disabled,
    outline = false,
    customClasses,
    type,
  }) {

    // console.log(disabled)
    return (
      <button
        disabled={disabled}
        onClick={onclick}
        className={`flex items-center text-sm ${
          outline ? "border border-yellow-50 bg-btn-red" : "bg-btn-red"
        } bg-btn-red cursor-pointer gap-x-2 rounded-md py-2 px-5 font-semibold text-white ${customClasses}`}
        type={type}
      >
        {children ? (
          <>
            <span className={`${outline && "text-yellow-50"}`}>{text}</span>
            {children}
          </>
        ) : (
          text
        )}
      </button>
    )
  }
  