import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { getPaymentSlips } from "../SERVICES/operations/UserOperations"
import { Spinner } from '@react-pdf-viewer/core';
import ViewPaymentSS from '../components/COMMON/ViewPaymentSS';

const PaymentSlips = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [viewImage, setViewImage] = useState(false)
    const [srcImage, setSrcImage] = useState("")

    const { token } = useSelector((state) => state.auth)
    // console.log(token)

    useEffect(() => {
        ; (async () => {
            if (token) {
                setLoading(true);
                const response = await getPaymentSlips(token);
                setData(response.data.slips);
                setLoading(false)
            }
        })()
    }, [])

    return (
        <div className=' w-full h-full overflow-hidden flex flex-col gap-4'>
            <p className='text-2xl font-bold text-center'>Payment Slips</p>
            
            {
                loading ? (<div className='w-full h-[calc(100vh-8rem)] flex justify-center items-center '>
                    <Spinner />
                </div>) : (
                    <>
                        <div className='w-full p-2 flex flex-wrap gap-2 overflow-auto'>
                            {
                                data.map((img) => {
                                    return (
                                        <div key={img._id} className="max-w-[250px] h-fit flex-col gap-2 space-y-2 bg-black p-2 bg-opacity-10">
                                            <img
                                                onClick={()=>{setViewImage((prev)=>!prev); setSrcImage(img)}}
                                                src={img.file}
                                                alt="Preview"
                                                className="h-full w-full rounded-md object-cover aspect-square hover:cursor-pointer"
                                            />
                                            <p className='text-center'>{img?.user?.firstName} {img?.user?.lastName}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                )
            }
            { viewImage && <ViewPaymentSS setViewImage={setViewImage} srcImage={srcImage} />}
        </div>
    )
}

export default PaymentSlips
