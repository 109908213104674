import React, { useEffect, useState } from "react"
// import { BiInfoCircle } from "react-icons/bi"
// import { HiOutlineGlobeAlt } from "react-icons/hi"
// import ReactMarkdown from "react-markdown"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ConfirmationModal from "../../COMMON/ConfirmationModal"
// import RatingStars from "../../COMMON/RatingStars"
import CourseAccordionBar from "../../CORE/Course/CourseAccordionBar"
import CourseDetailsCard from "../../CORE/Course/CourseDetailsCard"
import Spinner from "../../COMMON/Spinner"
// import { formatDate } from "../../../UTILS/formatDate"
// import { fetchCourseDetails } from "../../../SERVICES/operations/CourseOperations"
// import { BuyCourse } from "../SERVICES/operations/studentFeaturesAPI"
import { addToCart } from "../../../SLICES/CartSlice"
// import GetAvgRating from "../../../UTILS/avgRating"
// import Spinner from "../../COMMON/Spinner"
import { enqueueSnackbar } from "notistack"
import { ACCOUNT_TYPE } from "../../../UTILS/constants"
import { BuyCourse } from "../../../SERVICES/operations/UserOperations"
import calculateDiscount from "../../../UTILS/calcDiscount"
import { userEndpoints } from "../../../SERVICES/apis"
import { getUserDetails } from '../../../SERVICES/operations/UserOperations';
import { TypeAnimation } from 'react-type-animation'
// import { orderID } from "../../../UTILS/generateOrderId"

const {
  BUY_COURSE,
  BUY_COURSE_RESPONSE_API
} = userEndpoints

const CourseDetails = ({ selectedCourse }) => {

  const { user, token } = useSelector((state) => state.auth)
  // const { paymentLoading } = useSelector((state) => state.course)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // // console.log(user)
  // const [response, setResponse] = useState(null)
  const [confirmationModal, setConfirmationModal] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [user, setUser] = useState()
  // const [avgReviewCount, setAvgReviewCount] = useState(0)
  // useEffect(() => {
  //   const count = GetAvgRating(selectedCourse?.ratingAndReviews)
  //   setAvgReviewCount(count)
  // }, [selectedCourse])

  const [isActive, setIsActive] = useState(Array(0))
  const handleActive = (id) => {
    // // console.log("called", id)
    setIsActive(
      !isActive.includes(id)
        ? isActive.concat([id])
        : isActive.filter((e) => e !== id)
    )
  }

  const [totalNoOfLectures, setTotalNoOfLectures] = useState(0)
  useEffect(() => {
    let lectures = 0
    selectedCourse?.courseContent?.forEach((sec) => {
      lectures += sec.subSection.length || 0
    })
    setTotalNoOfLectures(lectures)
  }, [selectedCourse])

  // useEffect(() => {
  //   if (!user || user == null) {
  //     setLoading(true);
  //     if (token) {
  //       dispatch(getUserDetails(token, navigate));
  //     }
  //     setLoading(false);
  //   }
  // }, [user])

  //   if ( !selectedCourse) {
  //     return (
  //       <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
  //         <Spinner />
  //       </div>
  //     )
  //   }
  //   useEffect(() => {
  //     // Calling fetchCourseDetails fucntion to fetch the details
  //     ;(async () => {
  //       try {
  //         if(selectedCourse){
  //             // console.log(selectedCourse)
  //             const res = await fetchCourseDetails()
  //             // console.log("course details res: ", res)
  //             setResponse(res)
  //         }
  //       } catch (error) {
  //         // console.log("Could not fetch Course Details")
  //       }
  //     })()
  //   }, [])
  // // console.log(selectedCourse)
  const {
    _id: course_id,
    courseName,
    courseDescription,
    thumbnail,
    // price,
    whatYouWillLearn,
    courseContent,
    // ratingAndReviews,
    // instructor,
    // studentsEnroled,
    // createdAt,
    duration
  } = selectedCourse

  console.log(user)

  const generateOrderID = () => {
    // console.log("In the orderId function")
    if (!token || token == null) {
      enqueueSnackbar("Please Login to Continue Payment...", { variant: 'warning' })
      navigate('/login')
      return false
    }
    if (user.courses.includes(course_id)) {
      enqueueSnackbar("You're already enrolled in the Course...", { variant: 'info', autoHideDuration: '1s' })
      navigate('/dashboard/enrolled-courses')
      return false
    }
    const order_suffix = Math.floor(Math.random() * 90000) + 10000;
    const order_id = '2024' + order_suffix;
    // .toString().substring(2, 10);
    console.log(order_id);
    const userID = user._id;
    console.log(userID)
    // console.log(document.getElementById('order_id'))
    document.getElementById('order_id').value = order_id;
    document.getElementById('merchant_param2').value = user._id;
    // console.log("going to continue with submission")

    if (!user || user == null) {
      setLoading(true);
      if (token) {
        dispatch(getUserDetails(token, navigate));
      }
      setLoading(false);
    }

    return true
  }

  const handleAddToCart = () => {
    if (user && user?.accountType === ACCOUNT_TYPE.INSTRUCTOR) {
      enqueueSnackbar("You are an Instructor. You can't buy a course.", { variant: 'error' })
      return
    }
    if (token) {
      dispatch(addToCart(selectedCourse))
      return
    }

    setConfirmationModal({
      text1: "You are not logged in!",
      text2: "Please login to add To Cart",
      btn1Text: "Login",
      btn2Text: "Cancel",
      btn1Handler: () => navigate("/login"),
      btn2Handler: () => setConfirmationModal(null),
    })
  }

  // const handleBuyCourse = () =>{
  //   const res = axios.post()
  // }

  return (
    <>
      <div>
        {
          loading ? (<div><Spinner /></div>) : (
            <>
              <div className="mt-6 w-full sm:mt-0 relative">
                <div className="w-full text-end mr-4 px-3 font-bold text-base">
                  {/* <TypeAnimation className='text-base text-end '
                    sequence={[
                      'Welcome',
                      600,
                      `Welcome ${user.firstName}`, //  Continuing previous Text
                      600,
                      `Welcome ${user.firstName} ${user?.lastName}`,
                      600,
                      `Welcome ${user.firstName} ${user?.lastName} ...`,
                      600,
                      `Welcome ${user.firstName} ${user?.lastName}`,
                      600,
                      `Welcome ${user.firstName}`,
                      600,
                      `Welcome`,
                      600,
                      ' ',
                      600,
                      
                    ]}
                    style={{ fontSize: '2em' }}
                    repeat={Infinity} */}
                  {/* /> */}
                  { user && (<p>Welcome {user?.firstName} {user?.lastName}</p>)}
                </div>
                <div className="flex flex-col items-center justify-center w-full h-full relative"></div>
                <div className="mx-auto px-4 ">
                  <div className="mx-auto grid min-h-[450px] max-w-maxContentTab justify-items-center py-8 lg:mx-0 lg:justify-items-start lg:py-0 xl:max-w-[810px]">
                    <div className="relative block max-h-[30rem] lg:hidden">
                      <div className="absolute bottom-0 left-0 h-full w-full shadow-[#161D29_0px_-64px_36px_-28px_inset]"></div>
                      <img
                        src={thumbnail}
                        alt="course thumbnail"
                        className="aspect-auto w-full"
                      />
                    </div>
                    <div
                      className={` my-5 flex flex-col justify-center gap-4 py-5 text-lg text-richblack-5 `}
                    >
                      <div>
                        <p className='text-2xl sm:text-5xl font-serif font-semibold leading-15 text-btn-red'>
                          {courseName}
                        </p>
                      </div>
                      <p className={`text-richblack-200 lg:w-[64%] xl:w-9/12 2xl:w-11/12 text-justify `}>{courseDescription}</p>
                      {/* ratings */}
                      {/* <div className="text-md flex flex-wrap items-center gap-2">
                                <span >{avgReviewCount}</span>
                                <RatingStars Review_Count={avgReviewCount} Star_Size={24} />
                                <span>{`(${ratingAndReviews.length} reviews)`}</span>
                                <span>{`${studentsEnroled.length} students enrolled`}</span>
                            </div>
                            date of creation
                            <div className="flex flex-wrap gap-5 text-lg">
                                <p className="flex items-center gap-2">
                                    {" "}
                                    <BiInfoCircle /> Created at {formatDate(createdAt)}
                                </p>
                                <p className="flex items-center gap-2">
                                    {" "}
                                    <HiOutlineGlobeAlt /> English
                                </p>
                            </div> */}
                      <div>
                        <p className="text-2xl font-bold text-btn-red">What You'll learn: </p>
                        {
                          whatYouWillLearn.split('\n').map((learning, index) => {
                            return <li key={index} className=" m-2 lg:m-4 ml-6">{learning}</li>
                          })
                        }
                      </div>
                    </div>

                    <div className="w-full">
                      {
                        selectedCourse?.status === 'Live' && (
                          <div>
                            <span className="text-lg text-btn-red">Course Timings </span>: {selectedCourse?.courseTiming}
                          </div>
                        )
                      }
                    </div>

                    <div className="flex w-full mx-auto flex-col gap-4  border-y border-[#c8bfbf] py-4  ">
                      {
                        selectedCourse?.discountedPrice ? (
                          <>
                            <p>
                              Rs.<strike className="text-sm"> {selectedCourse.price}</strike>  /- <span className="text-green-500">{selectedCourse.discountedPrice} <span className="text-red-500 text-base">{calculateDiscount(selectedCourse.price, selectedCourse.discountedPrice)}% OFF</span></span>
                            </p>
                            <p></p>
                          </>
                        ) : (
                          <p>Rs. {selectedCourse.price}/-</p>
                        )
                      }
                      {
                        selectedCourse.status !== 'Upcoming' && (
                          <div className="md:flex md:pr-6 gap-4 justify-center max-md:space-y-5 ">
                            <form className="w-full md:w-1/2 flex flex-col" method="POST" onSubmit={generateOrderID} action={BUY_COURSE}>
                              <div className="hidden">
                                <input type="text" name="merchant_id" id="merchant_id" value="3407279" readOnly />
                                <input type="text" name="order_id" id="order_id" readOnly />
                                <input type="text" name="currency" value="INR" readOnly />
                                <input type="text" name="amount" value={selectedCourse.discountedPrice ? selectedCourse.discountedPrice : selectedCourse.price} readOnly />
                                <input type="text" name="redirect_url" value={BUY_COURSE_RESPONSE_API} readOnly />
                                <input type="text" name="cancel_url" value={BUY_COURSE_RESPONSE_API} readOnly />
                                <input type="text" name="merchant_param1" value={course_id} readOnly />
                                <input type="text" name="merchant_param2" id="merchant_param2" readOnly />
                                <input type="text" name="token" value={token} readOnly />
                                <input type="text" name="language" id="language" value="EN" readOnly />
                                <input type="text" name="billing_name" id="billing_name" value={`${user?.firstName} ${user?.lastName}`} readOnly />
                                <input type="text" name="billing_email" id="email" value={user?.email} readOnly />
                              </div>
                              <button type="submit"
                                className="bg-btn-red rounded-full animate-pulse text-white font-bold py-2 hover:animate-none"
                              >
                                Buy Now
                              </button>
                            </form>
                            {(!user || !selectedCourse?.studentsEnroled.includes(user?._id)) && (
                              <button onClick={handleAddToCart} className="w-full md:w-1/2 border border-btn-red rounded-full py-2 font-bold hover:text-white hover:bg-btn-red ease-in duration-75 ">
                                Add to Cart
                              </button>
                            )}
                          </div>
                        )
                      }
                    </div>

                  </div>
                  {/* Courses Card */}
                  <div className="right-[2rem] top-[60px] mx-auto hidden min-h-[600px] w-1/3 max-w-fit translate-y-32 md:translate-y-0 lg:absolute  lg:block">
                    <CourseDetailsCard
                      course={selectedCourse}
                      setConfirmationModal={setConfirmationModal}
                    //handleBuyCourse={handleBuyCourse}
                    />
                  </div>
                </div>
              </div>
              <div className="mx-auto box-content px-4 text-start text-richblack-5 lg:w-[1260px]">
                <div className="mx-auto max-w-maxContentTab lg:mx-0 xl:max-w-[810px]">

                  {/* Course Content Section */}
                  {selectedCourse.status === 'Published' && <div className="max-w-[830px] ">
                    <div className="flex flex-col gap-3">
                      <p className="text-[28px] font-semibold">Course Content</p>
                      <div className="flex flex-wrap justify-between gap-2">
                        <div className="flex gap-2">
                          <span>
                            {courseContent.length} {`section(s)`}
                          </span>
                          <span>
                            {totalNoOfLectures} {`lecture(s)`}
                          </span>
                          <span><span className="text-btn-red font-semibold">{duration}</span> total length</span>
                        </div>
                        <div>
                          <button
                            className="text-yellow-25"
                            onClick={() => setIsActive([])}
                          >
                            Collapse all sections
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Course Details Accordion */}
                    <div className="py-4">
                      {courseContent?.map((course, index) => (
                        <CourseAccordionBar
                          course={course}
                          key={index}
                          isActive={isActive}
                          handleActive={handleActive}
                        />
                      ))}
                    </div>
                  </div>}
                </div>
              </div>
              {
                confirmationModal && <ConfirmationModal modalData={confirmationModal} />
              }
            </>
          )
        }
      </div>
    </>
  )
}

export default CourseDetails