import React from 'react'
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'

function SuccessPage() {

    // window.location.replace("http://localhost:3000/success");

    return (
        <div className='w-screen h-[100vh] flex flex-col gap-4 justify-center items-center p-4'>
            <div></div>
            <img src='/logo.png' className='absolute top-4 w-[150px]' alt="logo" />
            <div className='text-btn-red font-bold'>
                <TypeAnimation
                    sequence={[
                        'Your',
                        500,
                        'Your Purchase', //  Continuing previous Text
                        500,
                        'Your Purchase is',
                        500,
                        'Your Purchase is Successfull!!',
                        500,
                        'Your Purchase is',
                        500,
                        'Your Purchase',
                        500,
                        'Your',
                        500,
                        ' ',
                        500,
                    ]}
                    style={{ fontSize: '2em' }}
                    repeat={Infinity}
                />
            </div>
            <p>You can now Continue with your course <Link to='/dashboard/enrolled-courses' className=' hover:underline font-bold'>click-here</Link></p>
        </div>
    )

}

export default SuccessPage