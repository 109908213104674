import { useRef, useState } from "react"
import { RxCross2 } from "react-icons/rx";
import { UploadScreenShot } from "../../SERVICES/operations/UserOperations";
import { useSelector } from "react-redux";

export default function AcceptPayment({ setPaymentModal }) {
    const [selectedFile, setSelectedFile] = useState(null)
    const [previewSource, setPreviewSource] = useState("")
    const inputRef = useRef(null)

    const { token } = useSelector((state) => state.auth)
    // console.log(token)

    const previewFile = (file) => {
        // console.log(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        // // console.log(reader)
        reader.onloadend = () => {
          setPreviewSource(reader.result)
          setSelectedFile(reader.result)
        }
      }

    const handleChange = (event) =>{
        const image  = event.target.files[0];
        setSelectedFile(image);
        previewFile(image)
    }

    const handleUpload = async () =>{
        const res =  await UploadScreenShot({
            token,
            screenshot: selectedFile || previewSource
        })
        setPreviewSource("")
        setSelectedFile(null)   
        setPaymentModal(false)
    }
  return (
    <div className="fixed inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-white bg-opacity-10 backdrop-blur-sm">
      <div className="w-fit max-w-[350px] rounded-lg border border-[#0000008a] bg-[#fff] bg-opacity-30 p-6">
        Payment Modal
        <div className="w-fit text-end items-end justify-end float-right mr-0">
            <button className="w-fit text-xl text-white rounded-md p-2 bg-btn-red hover:cursor-pointer" onClick={()=>setPaymentModal((prev)=>!prev)}>
                <RxCross2 />
            </button>
        </div>
        <div className="w-fit h-fit p-2 flex flex-col gap-4 space-y-3">
            <div className="text-black">
                <p>Account Name: Zieglar Byte Private Limited</p>
                <p>Account Number: 10162597030</p>
                <p>IFSC Code: IDFB0021027</p>
                <p>IFSC Code: Amritsar-Ranjit Avenue branch</p>
            </div>
            <div className="p-2 space-y-2 w-fit justify-center">
                <label htmlFor="screenshot" >Payment Screenshot</label>
                <input name="screenshot" type="file" accept="*.png, *.jpg, *.jpeg" onChange={handleChange} />
            </div>
            { selectedFile && <div className="max-w-[300px] ">
                <img ref={inputRef}
                    src={previewSource}
                    alt="Preview"
                    className="h-full w-full rounded-md object-cover aspect-square"
                    onChange={handleChange}
                />  
                </div>
            }
            <div className="w-full flex justify-center p-2 ">
                <button className="w-fit p-2 rounded-md text-white font-semibold bg-btn-red"
                    onClick={handleUpload}
                >
                    Upload Payment Screenshot
                </button>
            </div>
        </div>
      </div>
    </div>
  )
}
