import React, { useEffect, useRef, useState } from 'react'
// import Sidebar from '../components/COMMON/Sidebar'
import Sidebar from '../components/CORE/HOME/Sidebar'
// import { courseData } from '../UTILS/CourseData'
import { getAllCoursesHomePage } from '../SERVICES/operations/CourseOperations'
import Spinner from "../components/COMMON/Spinner"
import CourseDetails from '../components/CORE/HOME/CourseDetails'
import { GiHamburgerMenu } from "react-icons/gi";
import AcceptPayment from '../components/COMMON/AcceptPayment'
import { TypeAnimation } from 'react-type-animation'
import { useSelector } from 'react-redux'

const HomePage = () => {

    const [courses, setCourses] = useState([])
    const [selectedCourse, setSelectedCourse] = useState()
    const [loading, setLoading] = useState(true)
    const [viewSideBar, setViewSideBar] = useState(false)
    const [viewHamBurgerMenuButton, setViewHamBurgerMenuButton] = useState(true)
    const [paymentModal, setPaymentModal] = useState(false)
    const {user} = useSelector((state)=>state.auth)

    var publishedCourses = []
    var liveCourses = []
    var upcomingCourses = []

    const getCourses = async () => {
        // console.log(loading)
        const result = await getAllCoursesHomePage({setLoading})
        setLoading(false)
        // console.log(loading)
        // console.log(result)
        publishedCourses = result.filter(course => course.status === 'Published');
        liveCourses = result.filter(course => course.status === 'Live');
        upcomingCourses = result.filter(course => course.status === 'Upcoming');
        // // console.log(result)
        if (result) {
            setCourses(result)
        }

        setSelectedCourse(publishedCourses[0])
    }

    useEffect(() => {
        document.title = "Zieglar Byte | Courses"
        getCourses()
    }, [])

    return (
        <div className='h-screen w-screen flex overflow-hidden '>
            {
                loading ? (
                    <div className='w-full h-full flex justify-center items-center'>
                        <div className='flex flex-col  justify-center items-center'>
                            <img src='/logo.png' className='absolute animate-spin-slow w-[150px]' alt="logo" />
                            <TypeAnimation className='translate-y-28 '
                                sequence={[
                                    'Hold',
                                    600,
                                    'Hold On', //  Continuing previous Text
                                    600,
                                    'Hold On ....',
                                    600,
                                    'Hold On',
                                    600,
                                    'Hold',
                                    600,
                                    ' ',
                                    600,
                                    'You',
                                    600,
                                    'You are',
                                    600,
                                    'You are almost',
                                    600,
                                    'You are almost there',
                                    600,
                                    'You are almost there ....',
                                    600,
                                    'You are almost there',
                                    600,
                                    'You are almost',
                                    600,
                                    'You are',
                                    600,
                                    'You',
                                    600,
                                    ' ',
                                    600,
                                ]}
                                style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </div>
                    </div>
                ) :
                    <>
                        <div className="relative box-border flex h-100vh">
                            {
                                <div className='sm:hidden w-[100vw] h-16 z-10 absolute bg-[#fac5c5a3]'>
                                    {viewHamBurgerMenuButton && <GiHamburgerMenu className='absolute sm:hidden mt-4 ml-2 p-2 text-4xl  hover:bg-[#e5e2e2] hover:cursor-pointer' onClick={() => { setViewSideBar((prev) => !prev); setViewHamBurgerMenuButton((prev) => !prev) }} />}
                                </div>
                            }
                            <Sidebar courses={courses} setSelectedCourse={setSelectedCourse} selectedCourse={selectedCourse} publishedCourses={publishedCourses} liveCourses={liveCourses} upcomingCourses={upcomingCourses} viewSideBar={viewSideBar} setViewSideBar={setViewSideBar} viewHamBurgerMenuButton={viewHamBurgerMenuButton} setViewHamBurgerMenuButton={setViewHamBurgerMenuButton} paymentModal={paymentModal} setPaymentModal={setPaymentModal} />
                            <div className="h-full box-border md:w-[calc(100vw-10vw)] overflow-y-auto overflow-x-hidden">
                                <div className='p-4'>
                                    {
                                        selectedCourse && <CourseDetails selectedCourse={selectedCourse} />
                                    }
                                </div>
                            </div>
                        </div>
                    </>
            }
            {paymentModal && <AcceptPayment setPaymentModal={setPaymentModal} />}
        </div>

    )
}

export default HomePage