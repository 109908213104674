import { enqueueSnackbar } from 'notistack'
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateCertificateData } from '../../../../SERVICES/operations/UserOperations'
import { QRCodeCanvas } from "qrcode.react";

const AddQrDeatils = () => {

  const seminarCertificate = true;
  const qrRef = useRef(null);

  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)

  const [qrData, setQrData] = useState({
    attendeeName: '',
    attendeeEmail: '',
    seminarName: '',
    dateOfSeminar: ''
  })
  const [id, setId] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target
    setQrData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { attendeeName, attendeeEmail, seminarName, dateOfSeminar } = qrData;
    if (!attendeeEmail || attendeeEmail === '') {
      enqueueSnackbar('Please enter attendee email', { variant: 'warning' });
      return;
    }
    if (!attendeeName || attendeeName === '') {
      enqueueSnackbar('Please enter attendee name', { variant: 'warning' });
      return;
    }
    if (!seminarName || seminarName === '') {
      enqueueSnackbar('Please enter seminar name', { variant: 'warning' });
      return;
    }
    if (!dateOfSeminar || dateOfSeminar === '') {
      enqueueSnackbar('Please enter date of seminar', { variant: 'warning' });
      return;
    }

    const res = await dispatch(generateCertificateData({ attendeeName, attendeeEmail, seminarName, dateOfSeminar, seminarCertificate, token }))
    // console.log(res)
    if (res.data.success) {
      const { _id: certificateId } = res.data.certificateData;
      // console.log(certificateId)
      setId(certificateId)
    }
  }

  const handleCopyToClipboard = () => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
        const dataUrl = canvas.toDataURL('image/png');
        fetch(dataUrl)
          .then(res => res.blob())
          .then(blob => {
            const item = new ClipboardItem({ 'image/png': blob });
            navigator.clipboard.write([item])
              .then(() => {
                enqueueSnackbar('QR code copied to clipboard!', { variant: 'success' });
              })
              .catch(err => {
                enqueueSnackbar('Failed to copy QR code to clipboard', { variant: 'error' });
              });
          });
      }
    }
  }
  

  return (
    <div className='w-full h-[calc(100vh-5rem)] p-4 rounded-md flex flex-col items-center gap-4 text-lg border border-[#4a4a4c3c] '>
      <p className='text-center text-3xl font-bold'>Add QR Details</p>
      <div className='w-full h-fit flex mt-4'>
        <div className='w-7/12 flex flex-col gap-4 p-2 text-lg'>
          <label htmlFor='attendeeName' className='w-8/12 '> Attendee Name :</label>
          <label htmlFor='attendeeEmail' className='w-8/12 '> Attendee E-mail :</label>
          <label htmlFor='seminarName' className='w-8/12 '> Seminar Name :</label>
          <label htmlFor='dateOfSeminar' className='w-8/12 '> Date Of Seminar :</label>
          {id && <label htmlFor='certificateId' className='w-8/12 '> Certificate ID :</label>}
        </div>
        <div className='w-1/2 flex flex-col gap-4 p-2 text-lg'>
          <input required type='text' name='attendeeName' value={qrData.attendeeName} onChange={handleChange} />
          <input required type='email' name='attendeeEmail' value={qrData.attendeeEmail} onChange={handleChange} />
          <input required type='text' name='seminarName' value={qrData.seminarName} onChange={handleChange} />
          <input required type='date' name='dateOfSeminar' value={qrData.dateOfSeminar} onChange={handleChange} />
          {id && <input required type='text' name='certificateId' value={id} />}
        </div>
      </div>
      <button type='submit' onClick={handleSubmit} className='bg-btn-red p-4 rounded-md text-xl font-bold text-white'>
        Generate QR
      </button>
      {
        id && (
          <div className='w-full flex flex-col items-center' ref={qrRef}>
            <QRCodeCanvas
              value={`https://courses.zieglarbyte.com/verify-certificate/${id}`}
              size={150}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              imageSettings={{
                src: "/logo.png",
                x: undefined,
                y: undefined,
                height: 30,
                width: 30,
                excavate: true,
              }}
            />
            <button onClick={handleCopyToClipboard} className='mt-4 bg-blue-500 p-2 rounded-md text-white'>
              Copy QR to Clipboard
            </button>
          </div>
        )
      }
    </div>
  )
}

export default AddQrDeatils
