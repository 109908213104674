import { authEndpoints } from "../apis"
import { apiConnector } from "../ApiConnector";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { setToken, setUser, setLoading, setLoginData } from "../../SLICES/AuthSlice"

const {
SIGNUP_API,
  LOGIN_API,
  SEND_SIGNUP_OTP_API,
  SEND_LOGIN_OTP_API,
  VERIFY_LOGIN_OTP
} = authEndpoints

export function signup({
  accountType,
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  contactNumber,
  otp
}) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    let snackId = enqueueSnackbar("Signing Up...", { variant: 'info', persist: 'true' })
    try {
      const response = await apiConnector("POST", SIGNUP_API, {
        accountType,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        contactNumber,
        otp
      })

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      enqueueSnackbar("Signup Successfull!!", { variant: "success" })

    } catch (error) {
      // console.log('Error in Signup API ..... ', error)
      enqueueSnackbar(error.response.data.message, { variant: "error" });
    }
    finally {
      closeSnackbar(snackId)
      dispatch(setLoading(false))
    }
  }
}

export function login(email, password, navigate) {
  return async (dispatch) => {
    const snackId = enqueueSnackbar("Loging In...", { variant: 'info', persist: true });
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        password,
      })

      // console.log("LOGIN API RESPONSE............", response)

      if (!response.data.success) {
        throw new Error(response.data.message)
      }

      // if (response.data.user.accountType === 'Instructor' || response.data.user.accountType === 'Admin') {
      //   closeSnackbar(snackId)
      //   localStorage.setItem("loginData", JSON.stringify(response.data.user))
      //   dispatch(setLoginData(response.data.user))
      //   // await sendLoginpOTP(response.data.user.email).then(() => navigate('/verify-profile'))
      //   // console.log("in the login func after call")
      //   return
      // }

      enqueueSnackbar("Login Successful", { variant: 'success' })
      navigate('/verify-profile')
      const userImage = response.data?.user?.image
        ? response.data.user.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.firstName} ${response.data.user.lastName}`
      dispatch(setUser({ ...response.data.user, image: userImage }))
      localStorage.setItem("token", JSON.stringify(response.data.token))
      dispatch(setToken(response.data.token))
      navigate("/dashboard/my-profile")
    } catch (error) {
      // console.log("LOGIN API ERROR............", error)
      enqueueSnackbar(`${error.response.data.message}`, { variant: 'error' })
    }
    dispatch(setLoading(false))
    closeSnackbar(snackId)
  }
}

export function logout(navigate) {
  return async (dispatch) => {
    dispatch(setToken(null))
    dispatch(setUser(null))
    localStorage.setItem("token", null)
    localStorage.setItem("token", null)
    enqueueSnackbar("Logged out !!", { variant: 'success' })
    navigate("/")
  }
}

export async function sendSignUpOTP(contactNumber, email) {
  try {
    // console.log("In the func")
    const response = await apiConnector('POST', SEND_SIGNUP_OTP_API, { contactNumber, email })
    // console.log(response)
    if (!response.data.success) {
      throw new Error('Error while Sending OTP')
    }
    enqueueSnackbar('OTP sent Successfully!!', { variant: 'success' })
  } catch (error) {
    // console.log(error)
    enqueueSnackbar(`${error}`, { variant: 'error' })
  }
}

export async function sendLoginpOTP(email) {
  try {
    // console.log("In the func")
    const response = await apiConnector('POST', SEND_LOGIN_OTP_API, { email })
    // console.log(response)
    if (!response.data.success) {
      throw new Error('Error while Sending OTP')
    }
    enqueueSnackbar('OTP sent Successfully!!', { variant: 'success' })
  } catch (error) {
    // console.log(error)
    enqueueSnackbar(`${error}`, { variant: 'error' })
  }
}

export function verifyOTP(email, otp, navigate) {
  return async (dispatch) => {
    try {
      // console.log("In the func")
      const response = await apiConnector('POST', VERIFY_LOGIN_OTP, { email, otp });
      console.log(response.data.success)
      if (response.data.success) {
        // console.log("in the if ")
        const userData = JSON.parse(localStorage.getItem('loginData'))
        // console.log(userData);
        // return
        dispatch(setLoginData(null))
        localStorage.setItem("loginData", null)
        enqueueSnackbar("Login Successful", { variant: 'success' })
        const userImage = userData?.image
          ? userData.image
          : `https://api.dicebear.com/5.x/initials/svg?seed=${userData.firstName} ${userData.lastName}`
        dispatch(setUser({ ...userData, image: userImage }))
        localStorage.setItem("token", JSON.stringify(userData.token))
        dispatch(setToken(userData.token))
        navigate("/dashboard/my-profile")
      }
      else {
        console.log("in the else ")
        throw new Error(response.data.message)
      }

    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error}`, { variant: 'error' })
    }
  }
}